import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { View } from 'react-native';
import { HeaderUser, HeaderVenue, HeaderTeam, HeaderCompetition } from '@components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <hr></hr>
    <p>{`name: Header
menu: Components`}</p>
    <h2 {...{
      "id": "route-componentsheader"
    }}>{`route: /components/header`}</h2>

    <h1 {...{
      "id": "avatar"
    }}>{`Avatar`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={HeaderUser} mdxType="Props" />
    <h2 {...{
      "id": "header-user"
    }}>{`Header User`}</h2>
    <Playground __position={1} __code={'<HeaderUser\n  user={{\n    name: \'Ana Smith\',\n    gender: {\n      name: \'female\',\n    },\n  }}\n  onPressBackButton={() => {\n    console.log(\'press back\')\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      HeaderUser,
      HeaderVenue,
      HeaderTeam,
      HeaderCompetition,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <HeaderUser user={{
        name: 'Ana Smith',
        gender: {
          name: 'female'
        }
      }} onPressBackButton={() => {
        console.log('press back');
      }} mdxType="HeaderUser" />
    </Playground>
    <Playground __position={2} __code={'<HeaderUser\n  user={{\n    name: \'Marcel Popescu\',\n    profilePicture: \'https://picsum.photos/200\',\n    coverPicture: \'https://picsum.photos/400\',\n    gender: {\n      name: \'male\',\n    },\n  }}\n  onPressBackButton={() => {\n    console.log(\'press back\')\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      HeaderUser,
      HeaderVenue,
      HeaderTeam,
      HeaderCompetition,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <HeaderUser user={{
        name: 'Marcel Popescu',
        profilePicture: 'https://picsum.photos/200',
        coverPicture: 'https://picsum.photos/400',
        gender: {
          name: 'male'
        }
      }} onPressBackButton={() => {
        console.log('press back');
      }} mdxType="HeaderUser" />
    </Playground>
    <h2 {...{
      "id": "header-venue"
    }}>{`Header Venue`}</h2>
    <Playground __position={3} __code={'<HeaderVenue\n  venue={{\n    name: \'Camp Nou\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      HeaderUser,
      HeaderVenue,
      HeaderTeam,
      HeaderCompetition,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <HeaderVenue venue={{
        name: 'Camp Nou'
      }} mdxType="HeaderVenue" />
    </Playground>
    <Playground __position={4} __code={'<HeaderVenue\n  venue={{\n    name: \'National Arena\',\n    image:\n      \'https://upload.wikimedia.org/wikipedia/commons/1/1a/Stadionul_National_-_National_Arena_3.jpg\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      HeaderUser,
      HeaderVenue,
      HeaderTeam,
      HeaderCompetition,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <HeaderVenue venue={{
        name: 'National Arena',
        image: 'https://upload.wikimedia.org/wikipedia/commons/1/1a/Stadionul_National_-_National_Arena_3.jpg'
      }} mdxType="HeaderVenue" />
    </Playground>
    <h2 {...{
      "id": "header-team"
    }}>{`Header Team`}</h2>
    <Playground __position={5} __code={'<HeaderTeam\n  team={{\n    name: \'FC Barcelona\',\n    logo:\n      \'https://upload.wikimedia.org/wikipedia/sco/thumb/4/47/FC_Barcelona_%28crest%29.svg/1200px-FC_Barcelona_%28crest%29.svg.png\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      HeaderUser,
      HeaderVenue,
      HeaderTeam,
      HeaderCompetition,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <HeaderTeam team={{
        name: 'FC Barcelona',
        logo: 'https://upload.wikimedia.org/wikipedia/sco/thumb/4/47/FC_Barcelona_%28crest%29.svg/1200px-FC_Barcelona_%28crest%29.svg.png'
      }} mdxType="HeaderTeam" />
    </Playground>
    <Playground __position={6} __code={'<HeaderTeam\n  team={{\n    name: \'FCSB\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      HeaderUser,
      HeaderVenue,
      HeaderTeam,
      HeaderCompetition,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <HeaderTeam team={{
        name: 'FCSB'
      }} mdxType="HeaderTeam" />
    </Playground>
    <h2 {...{
      "id": "header-competition"
    }}>{`Header Competition`}</h2>
    <Playground __position={7} __code={'<HeaderCompetition\n  competition={{\n    name: \'La Liga\',\n    logo: \'https://ussoccerplayers.com/images/2018/05/la-liga-logo-spain.jpg\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      HeaderUser,
      HeaderVenue,
      HeaderTeam,
      HeaderCompetition,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <HeaderCompetition competition={{
        name: 'La Liga',
        logo: 'https://ussoccerplayers.com/images/2018/05/la-liga-logo-spain.jpg'
      }} mdxType="HeaderCompetition" />
    </Playground>
    <Playground __position={8} __code={'<HeaderCompetition\n  competition={{\n    name: \'Liga 1\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      View,
      HeaderUser,
      HeaderVenue,
      HeaderTeam,
      HeaderCompetition,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <HeaderCompetition competition={{
        name: 'Liga 1'
      }} mdxType="HeaderCompetition" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      